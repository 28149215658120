'use client';

import { PlayButton } from '@components/elements/play-button/play-button';
import { InlineVideoModal } from '@components/features/inline-video-modal/inline-video-modal';
import { type FC, useState } from 'react';

interface ImageSheetVideoProps {
    videoId: number;
    videoTitle: string;
}

export const ImageSheetVideo: FC<ImageSheetVideoProps> = ({
    videoId,
    videoTitle,
}) => {
    const [isVideoPopupOpened, setIsVideoPopupOpened] = useState(false);

    const handleOpenVideoPopupClick = () => {
        setIsVideoPopupOpened(true);
    };

    const handleVideoPopupClose = () => {
        setIsVideoPopupOpened(false);
    };

    return (
        <>
            <PlayButton
                title={videoTitle}
                onClick={handleOpenVideoPopupClick}
            />
            <InlineVideoModal
                id={videoId}
                title={videoTitle}
                isOpened={isVideoPopupOpened}
                onClose={handleVideoPopupClose}
            />
        </>
    );
};
