'use client';

import { Checkbox, CheckboxTick } from '@components/elements/checkbox/checkbox';
import { FractionNumber } from '@components/elements/fraction-number/fraction-number';
import type { RecipeIngredientFragment } from '@graphql/_generated-operation-types';
import { ariaUnit } from '@helpers/aria-unit';
import { adjustServings, normalizeIngredient } from '@helpers/ingredient';
import {
    ComponentAction,
    type ComponentMeta,
    emitComponentEvent,
} from '@royalaholddelhaize/ah-analytics';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { RECIPE_INGREDIENTS } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { isMetricUnit } from '@royalaholddelhaize/ah-ui-recipe-card/src/utils/metric-units';
import { VisuallyHidden } from '@royalaholddelhaize/design-system-pantry-web/components/utilities/visually-hidden/visually-hidden';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';

import css from './recipe-ingredients-ingredient-list.module.scss';

export interface RecipeIngredientsListProps {
    ingredients: RecipeIngredientFragment[];
    excludedIngredients: number[];
    handleOnExcludeIngredients: (ingredientIds: number[]) => void;
    servingNumber: number;
    title: string;
}

export const RecipeIngredientsList: FC<RecipeIngredientsListProps> = ({
    ingredients,
    excludedIngredients,
    handleOnExcludeIngredients,
    servingNumber,
    title,
}) => {
    const { t } = useTranslations();
    const normalizedIngredients = adjustServings({
        ingredients,
        servingNumber,
    }).map(normalizeIngredient);

    // we pass the ingredientId as ingredientName to the handler.
    // IngredientName is used for the analytics
    const handleIngredientToggle = (
        ingredientId: number,
        ingredientName: string,
        isIngredientSelected: boolean,
    ) => {
        const cidComponent: ComponentMeta = {
            componentType: 'Recipe ingredient',
            componentSubType: 'checkbox',
            componentInnerText: ingredientName,
            componentTitle: title,
            componentSection: 'Allerhande - Recipes',
        };

        emitComponentEvent({
            componentAction: isIngredientSelected
                ? ComponentAction.REMOVE
                : ComponentAction.ADD,
            component: cidComponent,
        });

        if (isIngredientSelected) {
            handleOnExcludeIngredients([...excludedIngredients, ingredientId]);
        } else {
            handleOnExcludeIngredients(
                excludedIngredients.filter(id => id !== ingredientId),
            );
        }
    };

    return (
        <>
            <table
                aria-label={t('recipe-details:ingredients.title')}
                className={css.table}
                aria-rowcount={normalizedIngredients.length}
                aria-colcount={3}
                //biome-ignore lint/a11y/useSemanticElements: table is used for tabular data
                //biome-ignore lint/a11y/noRedundantRoles: above comment
                role="table"
                {...createTestHook(RECIPE_INGREDIENTS)}
            >
                <tbody>
                    {normalizedIngredients.map((ingredient, idx) => {
                        const { name, unit, quantity, id } = ingredient;
                        const isIngredientSelected =
                            !excludedIngredients.includes(id);
                        const ingredientString = `${quantity} ${ariaUnit(t, unit || '')} ${name.normalized}`;

                        return (
                            <tr
                                key={`${id}-${name.normalized}-${quantity}${unit}`}
                            >
                                <td
                                    aria-colindex={1}
                                    aria-rowindex={idx + 1}
                                    //biome-ignore lint/a11y/useSemanticElements: table is used for tabular data
                                    //biome-ignore lint/a11y/noRedundantRoles: above comment
                                    role="cell"
                                >
                                    <Checkbox
                                        checked={isIngredientSelected}
                                        aria-label={t(
                                            'recipe-details:ingredients.checkbox',
                                            {
                                                ingredient: ingredientString,
                                            },
                                        )}
                                        id={id.toString()}
                                        onClick={() =>
                                            handleIngredientToggle(
                                                id,
                                                name.normalized,
                                                isIngredientSelected,
                                            )
                                        }
                                    >
                                        <CheckboxTick />
                                    </Checkbox>
                                </td>

                                <td
                                    aria-colindex={2}
                                    aria-rowindex={idx + 1}
                                    //biome-ignore lint/a11y/useSemanticElements: table is used for tabular data
                                    //biome-ignore lint/a11y/noRedundantRoles: above comment
                                    role="cell"
                                >
                                    <Typography
                                        className={css.unit}
                                        variant="body-strong"
                                    >
                                        {isMetricUnit(unit) ? (
                                            <span aria-hidden="true">
                                                {quantity}
                                            </span>
                                        ) : (
                                            <FractionNumber
                                                aria-hidden="true"
                                                quantity={quantity}
                                            />
                                        )}{' '}
                                        <span aria-hidden="true">{unit}</span>
                                        <VisuallyHidden>
                                            {ingredientString}
                                        </VisuallyHidden>
                                    </Typography>
                                </td>

                                <td
                                    aria-colindex={3}
                                    aria-rowindex={idx + 1}
                                    aria-hidden
                                >
                                    <Typography className={css.name}>
                                        {name.normalized}
                                    </Typography>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};
