'use client';

import { useContext } from 'react';
import type { HostData } from '../types';
import { HostContext } from './context';

export const useHost = (): HostData => {
    const context = useContext(HostContext);

    if (context === null) {
        throw new Error('useHost must be used within a HostProvider');
    }

    return context;
};
