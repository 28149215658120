export const sanitizeUrl = (url?: string | null): string | null => {
    try {
        if (!url) {
            return null;
        }

        // Split the URL into the base URL and the query string part (everything after 'query=')
        const [baseUrl, queryString] = url.split('query=');

        if (!queryString) {
            // If no 'query=' is found, return the original URL
            return decodeURIComponent(url);
        }

        // Split the query string at the first '&', keeping the query part separate from the rest
        const [queryValue, ...remainingParams] = queryString.split('&');

        // Decode the query value
        const decodedQuery = decodeURIComponent(queryValue);

        // Sanitize the query value by removing backslashes before quotes or apostrophes
        const sanitizedQuery = decodedQuery.replace(/\\+(?=['"])/g, '');

        // Re-encode the sanitized query value
        const reEncodedQuery = encodeURIComponent(sanitizedQuery);

        // Rebuild the URL with the sanitized query part
        const sanitizedUrl = `${decodeURIComponent(baseUrl)}query=${reEncodedQuery}${remainingParams.length > 0 ? `&${remainingParams.join('&')}` : ''}`;

        return sanitizedUrl;
    } catch (e) {
        console.error('Error processing URL:', e);
        return null; // Return null in case of any error
    }
};
