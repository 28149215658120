import classNames from 'clsx';
import type { FC } from 'react';
import { getSlot } from './helpers/get-slot';
import {
    type SpotlightAHRMSProps,
    SpotlightLayoutContext,
} from './spotlight-ahrms.interface';
import { SpotlightSlotAHRMS } from './spotlight-slot';

import css from './spotlight-container.module.scss';

export const SpotlightAHRMS: FC<SpotlightAHRMSProps> = ({
    slots,
    layout = SpotlightLayoutContext.FULL,
    className,
    ...restProps
}) => {
    return (
        <div
            data-testhook="spotlight-ahrms"
            className={classNames(css.root, className)}
            {...restProps}
        >
            {slots?.map(slotName => {
                const data = getSlot(slotName);

                if (!data) {
                    return null;
                }

                const { format, slot } = data;

                return (
                    <SpotlightSlotAHRMS
                        slot={slot}
                        format={format}
                        key={slot}
                        className={classNames(
                            css.banner,
                            layout === SpotlightLayoutContext.FULL
                                ? css.full
                                : undefined,
                        )}
                    />
                );
            })}
        </div>
    );
};
