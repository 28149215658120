import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    SUGGESTIONS_ADDED_TO_BASKET_MODAL,
    SUGGESTIONS_CLOSE,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import { ChevronLeft24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-left-24';
import { Close24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/close-24';
import {
    RegularAnchor,
    RegularAnchorText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalHeaderIconButton,
    ModalHeaderTitle,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/modal/modal';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC, MouseEvent } from 'react';
import { getModalTranslations } from '../../utils/translations';

import css from './add-to-basket-success-modal.module.scss';

interface AddToBasketSuccessModalModalProps {
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;

    isOpen: boolean;
    onClose(event?: MouseEvent<HTMLButtonElement>): void;
    onBack?(event?: MouseEvent<HTMLButtonElement>): void;
}

export const AddToBasketSuccessModal: FC<AddToBasketSuccessModalModalProps> = ({
    locale = DEFAULT_LOCALE,
    isOpen,
    onBack,
    onClose,
}) => {
    const { t } = getModalTranslations(locale);

    const handleOnClose = () => {
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleOnClose}
            className={css.modal}
            data-brand="allerhande"
            {...createTestHook(SUGGESTIONS_ADDED_TO_BASKET_MODAL)}
        >
            <ModalHeader className={css.modalHeader}>
                {onBack && (
                    <ModalHeaderIconButton
                        onClick={onBack}
                        aria-label={t('action.back')}
                    >
                        <ChevronLeft24Icon aria-hidden="true" />
                    </ModalHeaderIconButton>
                )}
                <ModalHeaderTitle aria-describedby="addToBasketSuccessDesc">
                    {t('basket.success.title')}
                </ModalHeaderTitle>
                <ModalHeaderIconButton
                    onClick={handleOnClose}
                    aria-label={t('action.close')}
                    {...createTestHook(SUGGESTIONS_CLOSE)}
                >
                    <Close24Icon aria-hidden="true" />
                </ModalHeaderIconButton>
            </ModalHeader>

            <ModalContent className={css.modalContent}>
                <Typography align="center" id="addToBasketSuccessDesc">
                    {t('basket.success.subtitle')}
                </Typography>
            </ModalContent>

            <div className={css.modalFooter}>
                <RegularAnchor
                    href="/mijnlijst"
                    className={css.button}
                    data-brand="ah"
                >
                    <RegularAnchorText>
                        {t('basket.success.button')}
                    </RegularAnchorText>
                </RegularAnchor>
            </div>
        </Modal>
    );
};
