import { useEffect, useState } from 'react';

export const useDomain = (): string => {
    const [domain, setDomain] = useState('');

    useEffect(() => {
        if (typeof window?.location !== 'undefined') {
            setDomain(window.location.host);
        }
    });

    return domain;
};
