import { isAccEnv } from '../../helpers/is-acc-env';
import { isTstEnv } from '../../helpers/is-tst-env';
import { normalizePathname } from '../../helpers/normalize-pathname';
import { normalizeTag } from '../../helpers/normalize-tag';
import {
    AdheseAccount,
    CustomerType,
    MemberStatusNumber,
    OrderMode,
} from '../adhese.enums';
import type { AdheseConfig, Member } from '../adhese.interfaces';
import { getCustomerType } from './get-customer-type';
import { getDeviceType } from './get-device-type';
import { getMemberStatusNumber } from './get-member-status-number';

const defaultConfig = {
    mi: [''],
    um: [MemberStatusNumber.NO_CONSENT],
    om: [OrderMode.NOT_IN_ORDER_MODE],
    cu: [CustomerType.UNKNOWN],
};

export type AdheseConfigInput = {
    isOrderMode: boolean;
    member: Member;
    /**
     * Unformatted pathname
     * @example `/producten/bakkerij`
     * @example `/allerhande/recepten/hutspot`
     */
    pathname: string;
    /**
     * @example large (pantry)
     * @example desktop (fresh-components)
     */
    viewport: string;
    /**
     * Consent given for ads
     */
    consentGiven: boolean;
    /**
     * @example 'ah_website_nl_flexpage_'
     */
    location: string;
    /**
     * All slots on the page
     * @example `['ah_website_nl_flexpage_1_1-flex', 'ah_website_nl_flexpage_1_2-flex']`
     */
    slots: string[];
    abTest?: string;
    /**
     * category
     */
    category?: string[];
    /**
     * Search keywords
     */
    searchKeywords?: string[];
    /**
     * Will use unformatted pathname for `jh` instead of `ur`
     */
    isRecipeGroup?: boolean;
    /**
     * Unformatted recipe tags
     * @example `['italiaans', 'zonder vlees/vis', 'oud & nieuw']`
     */
    recipeTags?: string[];
};

export const getAdheseConfig = (
    props?: AdheseConfigInput | null,
): AdheseConfig | null => {
    if (!props) {
        return null;
    }

    const {
        location,
        slots,
        member,
        pathname,
        viewport,
        isOrderMode = false,
        consentGiven = false,
        abTest,
        category,
        searchKeywords,
        isRecipeGroup,
        recipeTags,
    } = props;

    if (!member || !viewport || !slots || !location) {
        return null;
    }

    const pageUrl = pathname ? encodeURIComponent(pathname) : '';
    const deviceType = getDeviceType(viewport);

    let account = AdheseAccount.PRD;

    if (isTstEnv()) {
        account = AdheseAccount.TST;
    } else if (isAccEnv()) {
        account = AdheseAccount.ACC;
    }

    const config: AdheseConfig = {
        ...defaultConfig,
        location,
        slots,
        ur: !isRecipeGroup ? [pageUrl] : [],
        dt: [deviceType],
        consent: consentGiven,
        ab: abTest ? [abTest] : [],
        ct: category ?? [],
        kw: searchKeywords ?? [],
        jh: isRecipeGroup ? [normalizePathname(pathname)] : [],
        jt: recipeTags ? recipeTags.map(e => normalizeTag(e)) : [],
        account,
    };

    // if consent is given, add user's details to config
    if (consentGiven) {
        const memberId = member.digimon ?? '';
        const userMode = getMemberStatusNumber(member);
        const orderMode = isOrderMode
            ? OrderMode.IN_ORDER_MODE
            : OrderMode.NOT_IN_ORDER_MODE;
        const customerType = getCustomerType(member);

        config.mi = [memberId];
        config.um = [userMode];
        config.om = [orderMode];
        config.cu = [customerType];
    }

    return config;
};
