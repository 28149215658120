import PlayIcon from '@assets/icons/app-play.svg';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { VIDEO_PLAY_BUTTON } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Image } from '@royalaholddelhaize/design-system-pantry-web/components/image/image';
import classNames from 'clsx';
import type { FC } from 'react';
import css from './play-button.module.scss';

interface PlayButtonProps {
    onClick: () => void;
    className?: string;
    image?: string;
    title?: string;
}

export const PlayButton: FC<PlayButtonProps> = ({
    onClick,
    className,
    image,
    title,
}) => {
    const { t } = useTranslations();

    return (
        <button
            {...createTestHook(VIDEO_PLAY_BUTTON)}
            className={classNames(css.root, className)}
            type="button"
            aria-label={t('actions.play')}
            onClick={onClick}
        >
            <div
                className={classNames(css.outline, image && css.image)}
                tabIndex={-1}
            >
                {image && <Image src={image} resize="fill" alt={title || ''} />}
                <div className={css.playCircle}>
                    <PlayIcon
                        className={css.playIcon}
                        alt={t('actions.play')}
                        title={t('actions.play')}
                    />
                </div>
            </div>
        </button>
    );
};
