import AllerhandeLogoBlack from '@assets/logo/allerhande-logo-black.svg';
import AllerhandeLogoChristmas from '@assets/logo/allerhande-logo-white-christmas.svg';
import AllerhandeLogoWhite from '@assets/logo/allerhande-logo-white.svg';
import type { FC, HTMLAttributes } from 'react';

type LogoVariant = 'white' | 'black' | 'christmas';

export interface AllerhandeLogoProps extends HTMLAttributes<SVGElement> {
    variant?: LogoVariant;
}

type LogoMap = Record<LogoVariant, FC<HTMLAttributes<SVGElement>>>;

const logoMap: LogoMap = {
    black: AllerhandeLogoBlack,
    white: AllerhandeLogoWhite,
    christmas: AllerhandeLogoChristmas,
};

export const AllerhandeLogo: FC<AllerhandeLogoProps> = ({
    variant = 'white',
    ...restProps
}) => {
    const Component = logoMap[variant];

    return <Component {...restProps} />;
};
