import { Checkbox as PantryCheckbox } from '@royalaholddelhaize/design-system-pantry-web/components/checkbox/checkbox';
import type { CheckboxProps } from '@royalaholddelhaize/design-system-pantry-web/components/checkbox/checkbox.interfaces';
import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';

import css from './checkbox.module.scss';

export {
    CheckboxLabel,
    CheckboxTick,
} from '@royalaholddelhaize/design-system-pantry-web/components/checkbox/checkbox';

export const Checkbox: FC<CheckboxProps & ComponentProps<'div'>> = ({
    className,
    children,
    ...restProps
}) => {
    return (
        <PantryCheckbox
            className={classNames(css.checkbox, className)}
            {...restProps}
        >
            {children}
        </PantryCheckbox>
    );
};
