import type { TFunction } from '@royalaholddelhaize/ah-next-core/src/i18n/types';

/**
 * Some units are not spoken out correctly in aria voice over,
 * so a little transformer helper
 */
export const ariaUnit = (t: TFunction, unit: string) => {
    switch (unit) {
        case 'g':
            return t('unit.gram');
        case 'l':
            return t('unit.liter');
        case 'el':
            return t('unit.tablespoon');
        case 'tl':
            return t('unit.teaspoon');
        default:
            return unit;
    }
};
