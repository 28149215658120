'use client';

import { useContext } from 'react';
import type { ConsentData } from '../types';
import { ConsentContext } from './context';

export const useConsent = (): ConsentData => {
    const context = useContext(ConsentContext);

    if (context === null) {
        throw new Error('useConsent must be used within a ConsentProvider');
    }

    return context;
};
