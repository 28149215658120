import {
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import type { RegularButtonProps } from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button.interfaces';
import classNames from 'clsx';
import type { ComponentProps, FC, PropsWithChildren } from 'react';

import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { RECIPE_INGREDIENTS_SHOP_BUTTON } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import css from './recipe-ingredients-add-button.module.scss';

export const RecipeIngredientsAddButton: FC<
    PropsWithChildren<RegularButtonProps & ComponentProps<'button'>>
> = ({ children, className, ...restProps }) => {
    return (
        <RegularButton
            data-brand="ah"
            className={classNames(css.root, className)}
            {...createTestHook(RECIPE_INGREDIENTS_SHOP_BUTTON)}
            {...restProps}
        >
            <RegularButtonText data-brand="allerhande">
                {children}
            </RegularButtonText>
        </RegularButton>
    );
};
