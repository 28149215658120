import type { ComponentProps, FC } from 'react';

type FractionNumberProps = ComponentProps<'span'> & {
    quantity?: number | null;
};

export const FractionNumber: FC<FractionNumberProps> = ({
    quantity,
    ...rest
}) => {
    if (!quantity) {
        return null;
    }

    const integer = Math.trunc(quantity) || '';
    const remainder = quantity % 1;

    if (remainder > 0 && remainder <= 0.25) {
        return <span {...rest}>{integer}&#188;</span>;
    }

    if (remainder > 0.25 && remainder < 0.75) {
        return <span {...rest}>{integer}&#189;</span>;
    }

    if (remainder >= 0.75) {
        return <span {...rest}>{integer}&#190;</span>;
    }

    return <span {...rest}>{quantity}</span>;
};
