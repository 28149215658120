'use client';

import { useAdheseConfig } from '@components/features/advertisements/helpers/config';
import type { AdhesePosition } from '@components/features/advertisements/helpers/slots';
import { SpotlightAHRMSProvider } from '@royalaholddelhaize/ah-ui-ads';
import type { FC, PropsWithChildren } from 'react';

interface AdvertisementProviderProps extends PropsWithChildren {
    position?: AdhesePosition;
    tags?: string[];
}

export const AdvertisementProvider: FC<AdvertisementProviderProps> = ({
    position,
    children,
    tags,
}) => {
    if (!position) {
        return children;
    }

    // biome-ignore lint/correctness/useHookAtTopLevel: skip ads when position on page is undefined
    const adheseConfig = useAdheseConfig(position, {
        recipeTags: tags,
    });

    return (
        <SpotlightAHRMSProvider adheseConfigInput={adheseConfig}>
            {children}
        </SpotlightAHRMSProvider>
    );
};
