'use client';

import type { FC, PropsWithChildren } from 'react';
import type { HostData } from '../types';
import { HostContext } from './context';

interface HostProviderProps extends PropsWithChildren {
    data: HostData;
}

export const HostProvider: FC<HostProviderProps> = ({ data, children }) => {
    return <HostContext.Provider value={data}>{children}</HostContext.Provider>;
};
