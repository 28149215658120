import { SpotlightRenderType } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/spotlight-banner/elements/spotlight-image/spotlight-image.interfaces';
import { getAdheseRedirectUrl } from '../../adhese/helpers/is-external-link';
import type { AdheseContract } from '../spotlight-ahrms.interface';
import { getVariant } from './get-image';

export const validateRequest = (ad: AdheseContract): void => {
    if (!ad || typeof ad !== 'object') {
        throw new Error('Validation failed: `tag` is not a valid object.');
    }

    const validations: { key: string; condition: boolean }[] = [
        { key: 'subtitle', condition: !ad.subtitle },
        { key: 'title', condition: !ad.title },
        { key: 'visual', condition: !ad.visual },
        {
            key: 'visual.renderType',
            condition:
                ad.visual?.renderType?.toLowerCase() !==
                    SpotlightRenderType.COVER &&
                ad.visual?.renderType?.toLowerCase() !==
                    SpotlightRenderType.PACKSHOT,
        },
        {
            key: 'visual.image',
            condition:
                ad.visual?.renderType?.toLowerCase() ===
                    SpotlightRenderType.PACKSHOT &&
                !getVariant(ad.visual.image),
        },
        {
            key: 'visual.shortImage',
            condition:
                ad.visual?.renderType?.toLowerCase() ===
                    SpotlightRenderType.COVER &&
                !getVariant(ad.visual.shortImage),
        },
        {
            key: 'visual.longImage',
            condition:
                ad.visual?.renderType?.toLowerCase() ===
                    SpotlightRenderType.COVER &&
                !getVariant(ad.visual.longImage),
        },
        { key: 'link.href', condition: !ad.link?.href },
        {
            key: 'link.href format',
            condition: !getAdheseRedirectUrl(ad.link?.href),
        },
    ];

    const error = validations.find(v => v.condition);
    if (error) {
        throw new Error(
            `Validation failed: ${error.key} is invalid or missing.`,
        );
    }
};
