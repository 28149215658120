'use client';

import type { RecipeImageFragment } from '@graphql/_generated-operation-types';
import { createImageSrcSet } from '@helpers/image';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import type { ContentImageSet } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import { Image } from '@royalaholddelhaize/design-system-pantry-web/components/image/image';
import type { FC } from 'react';

import css from './image-sheet-image.module.scss';

interface ImageSheetImageProps {
    alt: string;
    images: (RecipeImageFragment | ContentImageSet)[];
}

export const ImageSheetImage: FC<ImageSheetImageProps> = ({ alt, images }) => {
    const { t } = useTranslations('recipe-details');

    return (
        <Image
            resize="fill"
            alt={alt}
            src={images[0]?.url || ''}
            srcSet={createImageSrcSet(images)}
            title={t('image.label', {
                title: alt,
            })}
            aria-hidden="true"
            className={css.image}
            level="primary"
        />
    );
};
