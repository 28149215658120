import type { AdheseAd, AdheseSlot as AdheseSlotInstance } from '@adhese/sdk';
import {
    type SpotlightCoverVisualProps,
    type SpotlightPackshotVisualProps,
    SpotlightRenderType,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/spotlight-banner/elements/spotlight-image/spotlight-image.interfaces';
import { SpotlightBanner } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/spotlight-banner/templates/spotlight-banner';
import type { SpotlightBannerProps } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/spotlight-banner/templates/spotlight-banner.interfaces';
import { isExternalLink } from '../adhese/helpers/is-external-link';
import { sanitizeUrl } from '../helpers/sanitize-url';
import { useDomain } from '../helpers/use-domain';
import { getImage, getVariant } from './helpers/get-image';
import type { AdheseContract } from './spotlight-ahrms.interface';

export type SpotlightTemplateProps = AdheseSlotInstance;

export const SpotlightTemplate = (slot: SpotlightTemplateProps) => {
    const domain = useDomain();
    const data = slot.data as AdheseAd<AdheseContract>;

    if (!data || !data.tag) {
        return null;
    }

    const {
        title,
        subtitle,
        link,
        sticker: stickerRaw,
        visual: visualRaw,
    } = data.tag as AdheseContract; // generic type from AdheseAd is not working nicely, ends up with being either string | AdheseContact anyway

    // decode url, and sanitize slashes, which are incorrectly added in the adhese tag
    const href = sanitizeUrl(link?.href);

    if (!href || !visualRaw) {
        return null;
    }

    const {
        renderType: renderTypeRaw,
        image: packshotImg,
        shortImage,
        longImage,
        theme,
    } = visualRaw;

    const stickerVariant = getVariant(stickerRaw);
    const sticker = stickerVariant
        ? {
              ...stickerVariant,
              description: stickerRaw?.description,
          }
        : undefined;

    const renderType = renderTypeRaw?.toLowerCase() as SpotlightRenderType;
    const coverShortImage = getVariant(shortImage) || {};
    const image = getImage({
        coverImage: longImage,
        packshotImage: packshotImg,
        renderType,
    });

    const visual =
        renderType === SpotlightRenderType.PACKSHOT
            ? ({
                  image,
                  theme,
              } as SpotlightPackshotVisualProps)
            : ({
                  image,
                  shortImage: {
                      ...coverShortImage,
                      description: shortImage.description,
                  },
              } as SpotlightCoverVisualProps);

    const isExternal = isExternalLink(link.href, domain);

    const props = {
        title,
        subtitle,
        link: {
            href,
            target: isExternal ? '_blank' : '_self',
            isExternalLink: isExternal,
        },
        renderType,
        sticker,
        visual,
    } as SpotlightBannerProps;

    return <SpotlightBanner {...props} />;
};
