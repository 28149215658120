import type { RecipeFragment } from '@graphql/_generated-operation-types';
import { listNameCreator } from '@helpers/analytics';
import { emitUpdateRecipeServingSize } from '@royalaholddelhaize/ah-analytics';
import {
    ServingsInput,
    type ServingsInputProps,
} from '@royalaholddelhaize/ah-ui-recipe-card/src/components/elements/servings-input/servings-input';
import classNames from 'clsx';
import type { FC } from 'react';

import css from './recipe-ingredients-serving.module.scss';

export type RecipeIngredientsServingProps = ServingsInputProps & {
    recipe: RecipeFragment;
};

export const RecipeIngredientsServing: FC<RecipeIngredientsServingProps> = ({
    recipe,
    servings,
    className,
    onChange,
    ...restProps
}) => {
    const handleOnChange = (amount: number) => {
        emitUpdateRecipeServingSize({
            ...{
                ...recipe,
                servings,
            },
            listName: listNameCreator(),
        });

        onChange?.(amount);
    };

    return (
        <ServingsInput
            servings={servings}
            className={classNames(css.root, className)}
            onChange={handleOnChange}
            {...restProps}
        />
    );
};
