import { Skeleton } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton';
import {
    SkeletonAnimation,
    SkeletonVariant,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton.interfaces';
import type { FC } from 'react';

import css from './spotlight-placeholder.module.scss';

export const SpotlightPlaceholder: FC = () => {
    return (
        <Skeleton
            className={css.root}
            variant={SkeletonVariant.RECTANGLE}
            animation={SkeletonAnimation.WAVE}
        />
    );
};
