import type {
    RecipeIngredientFragment,
    SingularPluralFragment,
} from '@graphql/_generated-operation-types';
import type { NormalizedIngredient } from '@interfaces/ingredient';
import { normalizeMetricUnits } from '@royalaholddelhaize/ah-ui-recipe-card/src/utils/metric-units';

export const roundQuantity = (quantity: number) =>
    Math.round(quantity * 100) / 100;

export const normalizeSingularPlural = (
    singularPlural: SingularPluralFragment,
    quantity?: number | null,
): string => {
    const { singular, plural } = singularPlural;
    if (!plural || !quantity) {
        return singular;
    }
    return quantity >= 2 ? plural : singular;
};

export const normalizeIngredient = (
    ingredient: RecipeIngredientFragment,
): NormalizedIngredient => {
    const quantityUnit = ingredient.quantityUnit
        ? normalizeSingularPlural(ingredient.quantityUnit, ingredient.quantity)
        : undefined;
    const { quantity, unit } = normalizeMetricUnits(
        ingredient.quantity,
        quantityUnit,
    );
    const name = normalizeSingularPlural(ingredient.name, quantity);

    return {
        id: ingredient.id,
        name: {
            normalized: name,
            singular: ingredient.name.singular,
            plural: ingredient.name.plural,
        },
        unit,
        quantity: quantity && roundQuantity(quantity),
    };
};

export function getIngredientText(ingredient: NormalizedIngredient): string {
    const { unit, name, quantity } = ingredient;
    const quantityString = quantity ? `${quantity} ` : '';
    const unitString = quantity && unit ? `${unit} ` : '';
    return `${quantityString}${unitString}${name.normalized}`;
}

export const roundIngredientQuantity = (
    ingredient: RecipeIngredientFragment,
    servingNumber: number,
) => Math.round(ingredient.servingsScale * servingNumber * 100) / 100;

export const adjustServings = ({
    ingredients,
    servingNumber,
}: {
    ingredients: RecipeIngredientFragment[];
    servingNumber: number;
}) => {
    const adjustedIngredients = ingredients.map(ingredient => ({
        ...ingredient,
        quantity: roundIngredientQuantity(ingredient, servingNumber),
    }));

    return adjustedIngredients;
};
