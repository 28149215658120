'use client';

import { VideoType } from '@constants/video';
import {
    type VideoMetaRaw,
    VideoProvider,
    emitCompleteVideo,
    emitStartVideo,
} from '@royalaholddelhaize/ah-analytics';
import { useConsent } from '@royalaholddelhaize/ah-next-core/src/consent/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { INLINE_VIDEO } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { BlueBillywigPlayer } from '@royalaholddelhaize/design-system-pantry-web/components/blue-billywig-player/blue-billywig-player';
import type {
    BlueBillywigClipData,
    BlueBillywigPlayer as BlueBillywigPlayerInstance,
} from '@royalaholddelhaize/design-system-pantry-web/hooks/use-blue-billywig/use-blue-billywig.interfaces';
import { useDebounceFn } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-debounce-fn/use-debounce-fn';
import classNames from 'clsx';
import { type FC, useState } from 'react';

import css from './inline-video.module.scss';

type InlineVideoProps = {
    id: number;
    title: string;
    videoPlayerType?: string;
    type?: VideoType;
    autoPlay?: boolean;
    className?: string;
};

const createVideoEventMetaData = (
    clipData: BlueBillywigClipData,
    duration: number | null,
): VideoMetaRaw => {
    // We have to pass the values down rather than using the player instance
    // because the player instance is asynchronous
    const { title, src, id } = clipData;
    const videoDuration = duration || 0;
    const roundedVideoDuration = Math.floor(videoDuration).toString();

    return {
        name: title,
        url: src,
        provider: VideoProvider.BLUE_BILLYWIG,
        id,
        videoDuration: roundedVideoDuration,
    };
};

export const InlineVideo: FC<InlineVideoProps> = ({
    id,
    videoPlayerType,
    type = VideoType.BLUEBILLIWIG,
    autoPlay = false,
    className,
}) => {
    // We need to keep track of whether the video has been played to prevent another analytics play fire after quick pause
    const [hasVideoBeenStarted, setHasVideoBeenStarted] =
        useState<boolean>(false);
    const { consent } = useConsent();
    const isAdvertisementEnabled = Boolean(consent && consent.ad);

    const handleOnVideoPlay = (player: BlueBillywigPlayerInstance) => {
        if (hasVideoBeenStarted || !player) {
            return;
        }
        const clipData = player.getClipData();
        const duration = player.getDuration();
        const videoMetadata = createVideoEventMetaData(clipData, duration);

        emitStartVideo(videoMetadata);
        setHasVideoBeenStarted(true);
    };

    // Debounce the video play event to prevent multiple events from firing on seek
    const debouncedHandleOnVideoPlay = useDebounceFn({
        fn: handleOnVideoPlay,
        delay: 400,
    });

    const handleOnVideoEnded = (player: BlueBillywigPlayerInstance) => {
        if (!player) {
            return;
        }

        const clipData = player.getClipData();
        const duration = player.getDuration();
        const videoMetadata = createVideoEventMetaData(clipData, duration);

        emitCompleteVideo(videoMetadata);
        setHasVideoBeenStarted(false);
    };

    return (
        <div
            className={classNames(css.root, className)}
            tabIndex={0}
            //biome-ignore lint/a11y/useSemanticElements: This is a div with a role button
            role="button"
            {...createTestHook(INLINE_VIDEO)}
        >
            <div className={css.video}>
                {type === VideoType.BLUEBILLIWIG && (
                    <BlueBillywigPlayer
                        className={css.thirdPartyPlayer}
                        accountName="allerhande"
                        videoId={String(id)}
                        onVideoPlay={debouncedHandleOnVideoPlay}
                        onVideoEnded={handleOnVideoEnded}
                        videoPlayerType={videoPlayerType}
                        playerOptions={{ autoPlay }}
                        isAdvertisementEnabled={isAdvertisementEnabled}
                    />
                )}
            </div>
        </div>
    );
};
