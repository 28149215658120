import type { AdheseAd } from '@adhese/sdk';
import type { PromoMetaProps } from '@royalaholddelhaize/ah-analytics';
import type { SpotlightRenderType } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/spotlight-banner/elements/spotlight-image/spotlight-image.interfaces';
import type { AdheseContract } from '../spotlight-ahrms.interface';
import { getImage } from './get-image';

export const getAnalyticsProps = (
    data: AdheseAd<AdheseContract>,
    position: string,
): PromoMetaProps => {
    const ad = data.tag as AdheseContract;
    const {
        visual: { longImage, image: packshotImage, renderType: renderTypeRaw },
    } = ad;

    const renderType = renderTypeRaw.toLowerCase() as SpotlightRenderType;
    const image = getImage({
        coverImage: longImage,
        packshotImage,
        renderType,
    });

    const [, index] = position.split('_');

    return {
        id: data.id || '',
        title: ad.title,
        href: image?.url,
        creativeId: data.libId,
        lineItemId: data.adspaceId
            ? Number.parseInt(data.adspaceId, 10)
            : undefined,
        promoListName: '',
        position: index,
        type: 'Adhese',
        promoListType: 'AHRMS Spotlight',
    };
};
