export enum AdheseAccount {
    TST = 'aholdtest',
    ACC = 'ahold',
    PRD = 'ahms',
}

export enum OrderMode {
    IN_ORDER_MODE = '1',
    NOT_IN_ORDER_MODE = '0',
}

export enum CustomerType {
    CONSUMER = 'c',
    BUSINESS = 'b',
    UNKNOWN = 'u',
}

export enum MemberStatusNumber {
    RETURNING = '1',
    LOGGEDON = '2',
    UNKNOWN = '1', // 3 should be only used for no consent
    NO_CONSENT = '3',
}
