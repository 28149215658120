'use client';

import type { AdheseOptions } from '@adhese/sdk';
import { AdheseProvider } from '@adhese/sdk-react';
import { type FC, type PropsWithChildren, useMemo } from 'react';
import { AdheseAccount } from '../adhese/adhese.enums';
import type { AdheseConfig } from '../adhese/adhese.interfaces';
import { getAdheseConfig } from '../adhese/helpers/get-adhese-config';
import { type SlotProps, getSlot } from './helpers/get-slot';
import type { SpotlightAHRMSProviderProps } from './spotlight-ahrms.interface';

export const SpotlightAHRMSProvider: FC<
    PropsWithChildren<SpotlightAHRMSProviderProps>
> = ({ adheseConfigInput, children }) => {
    const memoizedOptions = useMemo(() => {
        const adheseConfig = getAdheseConfig(adheseConfigInput);
        if (!adheseConfig) {
            return null;
        }

        const { account, consent, slots, location, ...parameters } =
            adheseConfig as AdheseConfig;

        if (!slots?.length || !location) {
            return null;
        }

        const initialSlots = slots
            .map(slotName => {
                const data = getSlot(slotName);

                if (!data) {
                    return null;
                }

                const { format, slot } = data;

                return {
                    format,
                    slot,
                };
            })
            .filter(Boolean) as SlotProps[];

        if (!initialSlots?.length) {
            return null;
        }

        const options: AdheseOptions = {
            location,
            consent,
            account,
            parameters,
            initialSlots,
            eagerRendering: true,
        };

        if (account === AdheseAccount.PRD) {
            // note: this is .nl, but atm also used for .be in PRD.
            options.host = 'https://zsp1.ah.nl';
        }

        return options;
    }, [adheseConfigInput]);

    if (!memoizedOptions) {
        return children;
    }

    return (
        <AdheseProvider options={memoizedOptions}>{children}</AdheseProvider>
    );
};
