export interface MetricUnit {
    name: string;
    synonyms: string[];
    factor: number;
    isPresentable: boolean;
}

export const MetricUnitsLiter: MetricUnit[] = [
    {
        name: 'l',
        synonyms: ['liter', 'ltr'],
        factor: 1,
        isPresentable: true,
    },
    {
        name: 'dl',
        synonyms: [],
        factor: 1e-1,
        isPresentable: false,
    },
    {
        name: 'cl',
        synonyms: [],
        factor: 1e-2,
        isPresentable: false,
    },
    {
        name: 'ml',
        synonyms: [],
        factor: 1e-3,
        isPresentable: true,
    },
];

export const MetricUnitsGrams: MetricUnit[] = [
    {
        name: 'kg',
        synonyms: [],
        factor: 1e3,
        isPresentable: true,
    },
    {
        name: 'g',
        synonyms: ['gram', 'gr'],
        factor: 1,
        isPresentable: true,
    },
    {
        name: 'dg',
        synonyms: [],
        factor: 1e-1,
        isPresentable: false,
    },
    {
        name: 'cg',
        synonyms: [],
        factor: 1e-2,
        isPresentable: false,
    },
    {
        name: 'mg',
        synonyms: [],
        factor: 1e-3,
        isPresentable: true,
    },
];
