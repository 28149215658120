import { Country } from '@royalaholddelhaize/ah-web-core';

export enum AdhesePosition {
    HOMEPAGE = '_',
    RECIPE = '_recept_',
    RECIPE_GROUP = '_recepten_',
}

export const getAdheseSlots = (
    domain: Country,
    position: AdhesePosition,
): string[] => {
    let slotsConfig: Record<AdhesePosition, string[]>;

    switch (domain) {
        case Country.BEL:
            slotsConfig = {
                [AdhesePosition.HOMEPAGE]: [
                    'ah_website_nl_allerhande_1_1-flex',
                    'ah_website_nl_allerhande_1_2-flex',
                ],
                [AdhesePosition.RECIPE]: [
                    'ah_website_nl_allerhande_recept_1_1-flex',
                    'ah_website_nl_allerhande_recept_1_2-flex',
                ],
                [AdhesePosition.RECIPE_GROUP]: [
                    'ah_website_nl_allerhande_recepten_1_1-flex',
                ],
            };
            break;
        case Country.NLD:
        default:
            slotsConfig = {
                [AdhesePosition.HOMEPAGE]: [
                    'ah_website_be_allerhande_1_1-flex',
                    'ah_website_be_allerhande_1_2-flex',
                ],
                [AdhesePosition.RECIPE]: [
                    'ah_website_be_allerhande_recept_1_1-flex',
                    'ah_website_be_allerhande_recept_1_2-flex',
                ],
                [AdhesePosition.RECIPE_GROUP]: [
                    'ah_website_be_allerhande_recepten_1_1-flex',
                ],
            };
            break;
    }

    return slotsConfig[position];
};
