import type { AdheseAccount } from './adhese.enums';

export interface AdheseConfig {
    account: AdheseAccount;
    location?: string;
    slots?: string[];
    mi?: string[]; // AH member id
    ur?: string[]; // base64 encoded page url
    br?: string[]; // browser info
    dt?: string[]; // deviceType
    ct?: string[]; // categories
    kw?: string[]; // search keywords
    um?: string[]; // user auth status
    om?: string[]; // order mode 1 | 0
    cu?: string[]; // customer type
    tl?: string[]; // should be set to 'all' only when um is 1 or 2
    ab?: string[]; // parameter to choose between campaign variants null | 'a' | 'b'
    jt?: string[]; // recipe tags allerhande
    jh?: string[]; // recipe group allerhande
    consent?: boolean;
}

export type Member = {
    status: MemberLoginState;
    id?: number;
    isBusiness?: boolean;
    digimon?: string;
    memberships?: string[] | null;
};

export enum MemberLoginState {
    /** Logged in customer */
    LOGGEDON = 'LOGGEDON',
    /** Returning recognised customer, not logged in */
    RETURNING = 'RETURNING',
    /** anonymous user */
    UNKNOWN = 'UNKNOWN',
}
