// todo: this can be improved as soon as we moved completely to Pantry
export const getDeviceType = (viewport: string): string => {
    const viewportType = viewport.toLowerCase();
    if (
        viewportType === 'phone' ||
        viewportType === 'phablet' ||
        viewportType === 'extra-small' ||
        viewportType === 'small'
    ) {
        return 'phone';
    }
    if (viewportType === 'tablet' || viewportType === 'medium') {
        return 'tablet';
    }
    return 'desktop';
};
