import {
    type SpotlightImage,
    SpotlightRenderType,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/spotlight-banner/elements/spotlight-image/spotlight-image.interfaces';
import type { AdheseImage } from '../spotlight-ahrms.interface';

type ImageProps = {
    renderType: SpotlightRenderType;
    packshotImage: AdheseImage;
    coverImage: AdheseImage;
};

export const getVariant = (image: AdheseImage) => {
    if (!image?.variants?.length) {
        return null;
    }
    return image.variants.find(variant => {
        const url = variant.url;

        try {
            new URL(url);
            return true; // URL is valid
        } catch (_e) {
            return false; // URL is invalid
        }
    });
};

export const getImage = (props: ImageProps) => {
    const { renderType, packshotImage, coverImage } = props;

    const packshotImg = getVariant(packshotImage) || {};
    const coverImg = getVariant(coverImage) || {};

    return (
        renderType === SpotlightRenderType.PACKSHOT
            ? {
                  ...packshotImg,
                  description: packshotImage.description,
              }
            : {
                  ...coverImg,
                  description: coverImage.description,
              }
    ) as SpotlightImage;
};
