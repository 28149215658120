import type { SpotlightRenderType } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/spotlight-banner/elements/spotlight-image/spotlight-image.interfaces';
import type { ComponentProps } from 'react';
import type { AdheseConfigInput } from '../adhese/helpers/get-adhese-config';

export type SpotlightAHRMSProviderProps = {
    adheseConfigInput?: AdheseConfigInput | null;
};

export type SpotlightAHRMSProps = ComponentProps<'div'> & {
    slots: string[];
    layout?: SpotlightLayoutContext;
};

export type SpotlightAHRMSSlotProps = Omit<ComponentProps<'div'>, 'ref'> & {
    format: string;
    slot: string;
};

export enum SpotlightLayoutContext {
    SIDEBAR = 'sidebar',
    FULL = 'full',
}

export type AdheseImage = {
    variants: {
        url: string;
        width: number;
        height: number;
    }[];
    description: string;
};

export type AdheseContract = {
    title: string;
    subtitle: string;
    link: {
        href: string;
        target: string;
        title: string;
    };
    visual: {
        renderType: SpotlightRenderType;
        image: AdheseImage;
        longImage: AdheseImage;
        shortImage: AdheseImage;
        theme: string;
    };
    sticker: AdheseImage;
};
