// determine if the adhese redirect url is an external link
export const isExternalLink = (url: string, domain: string): boolean => {
    const href = getAdheseRedirectUrl(url);
    if (!href) return false;

    if (
        href.startsWith('/') || // when url is relative, we consider it as internal link
        href.startsWith(`https://${domain}`) || // when url is within the same domain, we consider it as internal link
        href.startsWith(`https://www.${domain}`)
    ) {
        return false;
    }

    return true;
};

export const getAdheseRedirectUrl = (url: string): string | null => {
    // adhese provides the redirect url behind /UR
    if (!url?.includes('/UR') || url?.endsWith('/UR')) return null; // Ensure "/UR" exists and is not the last part of the url

    const redirect = url.split('/UR').slice(-1).toString();
    return decodeURIComponent(redirect);
};
