import type { ApolloError } from '@apollo/client';
import {
    type ProductMetaPropsWithQuantity,
    emitAddProductsToCart,
} from '@royalaholddelhaize/ah-analytics';
import { useAddBasketItemsMutation } from '../_generated-hooks';
import type {
    AddBasketItemsMutation,
    BasketMutation,
} from '../_generated-operation-types';

const filterOutVagueTerms = (items: BasketMutation[]) =>
    items.filter(({ id }) => id);

export const normalizeCDPBasketAnalytics = (
    items: BasketMutation[],
    listName = 'allerhande',
): ProductMetaPropsWithQuantity[] => {
    return filterOutVagueTerms(items).map((item, index) => ({
        id: item.id ?? 0,
        quantity: item.quantity,
        listName,
        index,
    }));
};

export interface UseBasketItemsAdd {
    isError: boolean;
    isLoading: boolean;
    addBasketItems(items: BasketMutation[]): void;
}

interface UseBasketItemsAddParams {
    onComplete?: (data: AddBasketItemsMutation) => void;
    onError?: (error: ApolloError) => void;
    listName?: string;
    member: {
        isRegistered: boolean;
    };
}

export const useAddToBasket = ({
    onComplete,
    onError,
    member,
    listName,
}: UseBasketItemsAddParams): UseBasketItemsAdd => {
    const [basketItemsAdd, { loading, error }] = useAddBasketItemsMutation({
        context: {
            headers: member.isRegistered
                ? undefined
                : { 'X-Require-Member': true },
        },
    });

    const addBasketItems = (items: BasketMutation[]) => {
        if (items.length) {
            basketItemsAdd({
                variables: {
                    items,
                },
                onError: error => {
                    onError && onError(error);
                },
                onCompleted: data => {
                    emitAddProductsToCart(
                        normalizeCDPBasketAnalytics(items, listName),
                    );

                    onComplete && onComplete(data);
                },
            }).catch();
        }
    };

    return {
        isError: !loading && !!error,
        isLoading: loading,
        addBasketItems,
    };
};
