import { CustomerType } from '../adhese.enums';
import type { Member } from '../adhese.interfaces';

export const getCustomerType = (
    member: Member | undefined | null,
): CustomerType => {
    if (!member || !member.id) {
        return CustomerType.UNKNOWN;
    }
    return member.isBusiness ? CustomerType.BUSINESS : CustomerType.CONSUMER;
};
