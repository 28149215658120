import {
    type MetricUnit,
    MetricUnitsGrams,
    MetricUnitsLiter,
} from '../constants/metric-units';
import type { Maybe } from '../graphql/_generated-graphql-types';

const metricUnits = Object.values([MetricUnitsGrams, MetricUnitsLiter]);

export const getPresentableUnits = (metricUnit: MetricUnit[]): MetricUnit[] => {
    return metricUnit.filter(unit => unit.isPresentable);
};

export const getMetricUnit = (unit: string): MetricUnit[] | undefined => {
    return metricUnits.find(metricUnit =>
        metricUnit.some(({ synonyms, name }) =>
            [name, ...synonyms].includes(unit.toLowerCase()),
        ),
    );
};

export const isMetricUnit = (unit?: string | null): boolean =>
    Boolean(unit && getMetricUnit(unit));

export const normalizeMetricUnits = (
    quantity: Maybe<number> | undefined,
    unit: Maybe<string> | undefined,
): { quantity: Maybe<number> | undefined; unit: Maybe<string> | undefined } => {
    if (!unit || !quantity) {
        return { unit, quantity };
    }

    const metricUnit = getMetricUnit(unit);

    if (!metricUnit) {
        return { unit, quantity };
    }

    const currentUnit = metricUnit.find(({ synonyms, name }) =>
        [name, ...synonyms].includes(unit.toLowerCase()),
    );

    if (!currentUnit) {
        return { unit, quantity };
    }

    const unscaledAmount = quantity * currentUnit.factor;

    const presentableUnits = getPresentableUnits(metricUnit);

    const newUnit = presentableUnits.find(
        ({ factor }) => unscaledAmount >= factor,
    );

    if (!newUnit) {
        return { unit, quantity };
    }

    return {
        unit: newUnit.name,
        quantity: unscaledAmount / newUnit.factor,
    };
};
