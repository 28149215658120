/**
 * Normalize pathname
 *
 * Should only include:
 * - Lowercase plain letters (so only the 26 letters available in the alphabet)
 * - Underscores `_`
 * - All other signs should be replaced by one of these options..
 */
export const normalizePathname = (pathname: string) =>
    pathname
        .split('/')
        .filter(e => e.trim().length > 0)
        .join('_')
        .replace(/[^a-z_]/g, '_');
