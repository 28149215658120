'use client';

import { Banner, Country } from '@royalaholddelhaize/ah-web-core';
import { createContext } from 'react';
import type { HostData } from '../types';

export const HostContext = createContext<HostData>({
    domain: Country.NLD,
    banner: Banner.AH,
});
