import { MemberStatusNumber } from '../adhese.enums';
import type { Member } from '../adhese.interfaces';

export const getMemberStatusNumber = (
    member?: Member | null,
): MemberStatusNumber => {
    return (
        (member && MemberStatusNumber[member.status]) ||
        MemberStatusNumber.UNKNOWN
    );
};
