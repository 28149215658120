import {
    AdhesePosition,
    getAdheseSlots,
} from '@components/features/advertisements/helpers/slots';
import { useAuthentication } from '@royalaholddelhaize/ah-next-core/src/authentication/client';
import { useConsent } from '@royalaholddelhaize/ah-next-core/src/consent/client';
import { LoginState } from '@royalaholddelhaize/ah-next-core/src/enums/login-state';
import { useHost } from '@royalaholddelhaize/ah-next-core/src/host/client';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import { MemberLoginState } from '@royalaholddelhaize/ah-ui-ads/src/adhese/adhese.interfaces';
import type { AdheseConfigInput } from '@royalaholddelhaize/ah-ui-ads/src/adhese/helpers/get-adhese-config';
import { Country } from '@royalaholddelhaize/ah-web-core';
import { usePathname } from 'next/navigation';

const memberStatusMapper = {
    [LoginState.LOGGEDON]: MemberLoginState.LOGGEDON,
    [LoginState.RETURNING]: MemberLoginState.RETURNING,
    [LoginState.ANONYMOUS]: MemberLoginState.UNKNOWN,
};

export const useAdheseConfig = (
    position?: AdhesePosition,
    config?: Partial<AdheseConfigInput>,
): AdheseConfigInput | null => {
    const { state, memberId, memberships, isB2B } = useAuthentication();
    const { domain } = useHost();
    const { consent } = useConsent();
    const { viewport } = useViewport();
    const pathname = usePathname();
    const consentGiven = Boolean(consent && consent.ad);

    if (!position) {
        return null;
    }

    return {
        ...config,
        isRecipeGroup: [
            AdhesePosition.HOMEPAGE,
            AdhesePosition.RECIPE_GROUP,
        ].includes(position),
        isOrderMode: false,
        member: {
            id: memberId || 0,
            memberships,
            isBusiness: isB2B,
            status: memberStatusMapper[state],
        },
        pathname,
        viewport,
        consentGiven,
        location: `ah_website_${domain === Country.BEL ? 'be' : 'nl'}_allerhande${position}`,
        slots: getAdheseSlots(domain, position),
    };
};
