import type { RecipeOriginType } from '@graphql/_generated-operation-types';
import { RecipeCollectionContext } from '@royalaholddelhaize/ah-ui-recipe-card/src/contexts/recipe-collection-context';
import type { RecipeImage } from '@royalaholddelhaize/ah-ui-recipe-card/src/graphql/_generated-graphql-types';
import { useContext } from 'react';

export interface UseRecipeCardHook {
    isFavoriteEnabled: boolean;
    isFavoriteLoading: boolean;
    isFavorite: boolean;
    isFavoriteChangeError: boolean;
    openFavorite: () => void;
}

export const useImageSheetFavorite = (recipe: {
    id: number;
    type: RecipeOriginType;
    images?: RecipeImage[];
}): UseRecipeCardHook => {
    const recipeCollectionContext = useContext(RecipeCollectionContext);

    if (!recipeCollectionContext) {
        return {
            isFavorite: false,
            isFavoriteEnabled: false,
            isFavoriteLoading: false,
            isFavoriteChangeError: false,
            openFavorite: () => null,
        };
    }

    const isFavoriteEnabled =
        recipeCollectionContext.canFavoriteRecipes &&
        !recipeCollectionContext.isLoading;

    const collectedRecipe = recipeCollectionContext.categories
        .flatMap(e => e.list)
        .find(({ id }) => id === recipe.id);
    const isFavorite = Boolean(collectedRecipe);

    return {
        isFavorite,
        isFavoriteEnabled,
        isFavoriteLoading: recipeCollectionContext.isChangeLoading,
        isFavoriteChangeError: recipeCollectionContext.isChangeError,
        openFavorite: () => recipeCollectionContext.handleOnOpen(recipe),
    };
};
