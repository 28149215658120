import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import { ChevronLeft24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-left-24';
import { Close24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/close-24';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalHeaderIconButton,
    ModalHeaderTitle,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/modal/modal';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC, MouseEvent } from 'react';
import { getModalTranslations } from '../../utils/translations';

import css from './add-to-basket-failure-modal.module.scss';

interface AddToBasketFailureModalModalProps {
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;
    isOpen: boolean;
    onClose(event?: MouseEvent<HTMLButtonElement>): void;
    onBack?(event?: MouseEvent<HTMLButtonElement>): void;
}

export const AddToBasketFailureModal: FC<AddToBasketFailureModalModalProps> = ({
    locale = DEFAULT_LOCALE,
    isOpen,
    onBack,
    onClose,
}) => {
    const { t } = getModalTranslations(locale);

    const handleOnClose = () => {
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleOnClose}
            className={css.modal}
            data-brand="allerhande"
        >
            <ModalHeader className={css.modalHeader}>
                {onBack && (
                    <ModalHeaderIconButton
                        onClick={onBack}
                        aria-label={t('action.back')}
                    >
                        <ChevronLeft24Icon aria-hidden="true" />
                    </ModalHeaderIconButton>
                )}
                <ModalHeaderTitle aria-describedby="addToBaskedFailedDesc">
                    {t('basket.failure.title')}
                </ModalHeaderTitle>
                <ModalHeaderIconButton
                    onClick={handleOnClose}
                    aria-label={t('action.close')}
                >
                    <Close24Icon aria-hidden="true" />
                </ModalHeaderIconButton>
            </ModalHeader>

            <ModalContent className={css.modalContent}>
                <Typography align="center" id="addToBaskedFailedDesc">
                    {t('basket.failure.subtitle')}
                </Typography>
            </ModalContent>
        </Modal>
    );
};
