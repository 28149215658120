import type { RecipeKitchenApplianceFragment } from '@graphql/_generated-operation-types';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { RECIPE_APPLIANCES } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';

import css from './recipe-ingredients-kitchen.module.scss';

export interface RecipeIngredientsKitchenProps {
    kitchenAppliances: RecipeKitchenApplianceFragment[];
}

export const RecipeIngredientsKitchen: FC<RecipeIngredientsKitchenProps> = ({
    kitchenAppliances,
}) => {
    const { t } = useTranslations('recipe-details');

    return (
        <>
            <Typography variant="heading-2" as="h3">
                {t('appliances.title')}
            </Typography>

            <ul
                className={css.appliancesList}
                {...createTestHook(RECIPE_APPLIANCES)}
            >
                {kitchenAppliances.map(appliances => (
                    <li key={appliances.name}>
                        <Typography className={css.appliance}>
                            {appliances.name}
                        </Typography>
                    </li>
                ))}
            </ul>
        </>
    );
};
