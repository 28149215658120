import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';
import css from './rich-content.module.scss';

interface RichContentProps extends ComponentProps<'div'> {
    html: string;
}

export const RichContent: FC<RichContentProps> = ({
    className,
    html,
    ...restProps
}) => (
    <div
        className={classNames(css.root, className)}
        // biome-ignore lint/security/noDangerouslySetInnerHtml: This is a trusted source
        dangerouslySetInnerHTML={{ __html: html }}
        {...restProps}
    />
);
