'use client';

import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { RECIPE_STEPS_PREPARATION } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC, ReactNode } from 'react';

import css from './recipe-steps.module.scss';

type RecipeStepsProps = {
    steps: (string | ReactNode)[];
};

export const RecipeSteps: FC<RecipeStepsProps> = ({ steps }) => {
    const { t } = useTranslations('recipe-details');

    return (
        <article className={css.root}>
            <ol
                className={css.steps}
                {...createTestHook(RECIPE_STEPS_PREPARATION)}
            >
                {steps.map((step, index) =>
                    step ? (
                        <li
                            key={typeof step === 'string' ? step : index}
                            className={css.step}
                            aria-label={t('steps.step', { step: index + 1 })}
                        >
                            <div className={css.circle} aria-hidden="true">
                                {index + 1}
                            </div>
                            {typeof step === 'string' ? (
                                <Typography className={css.stepText}>
                                    {step}
                                </Typography>
                            ) : (
                                step
                            )}
                        </li>
                    ) : null,
                )}
            </ol>
        </article>
    );
};
