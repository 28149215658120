export type SlotProps = {
    format: string;
    slot: string;
};

export const getSlot = (slotName: string): SlotProps | null => {
    // Get the last two parts of the slot name, separated by either '_' or '-'
    const slotParts = slotName.split('_').slice(-2).join('_');

    // Extract the slot position and format (assuming the last two parts are position and format)
    const [slot, format] = slotParts.split('-');

    if (!/^\d+/.test(slot) || !format) {
        console.error(`invalid slot name: ${slotName}`);
        return null;
    }

    return {
        format,
        slot,
    };
};
