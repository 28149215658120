'use client';

import HeartFilledIcon from '@assets/icons/heart-filled.svg';
import HeartPlusIcon from '@assets/icons/heart-plus.svg';
import { RecipeOriginType } from '@graphql/_generated-operation-types';
import { listNameCreator } from '@helpers/analytics';
import { emitInitializeFavoriteRecipes } from '@royalaholddelhaize/ah-analytics';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC, PropsWithChildren } from 'react';
import { useImageSheetFavorite } from './image-sheet-favorite-hook';

import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { RECIPE_HEADER_FAVORITE_BUTTON } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import css from './image-sheet-favorite.module.scss';

export interface ImageSheetFavoriteProps {
    recipeId: number;
    recipeType?: RecipeOriginType | null;
}

export const ImageSheetFavorite: FC<
    PropsWithChildren<ImageSheetFavoriteProps>
> = ({ recipeId, recipeType }) => {
    const { t } = useTranslations('recipe-details');
    const { openFavorite, isFavorite } = useImageSheetFavorite({
        id: recipeId,
        type: recipeType || RecipeOriginType.ALLERHANDE,
    });

    const handleClick = () => {
        openFavorite();

        emitInitializeFavoriteRecipes({
            id: recipeId,
            listName: listNameCreator(),
        });
    };

    return (
        <button
            type="button"
            className={css.root}
            onClick={handleClick}
            {...createTestHook(RECIPE_HEADER_FAVORITE_BUTTON)}
        >
            <span tabIndex={-1} className={css.button}>
                {isFavorite ? (
                    <HeartFilledIcon width={24} height={24} />
                ) : (
                    <HeartPlusIcon width={24} height={24} />
                )}
                <Typography
                    variant="body-strong"
                    className={css.text}
                    as="span"
                >
                    {isFavorite ? t('favorite.added') : t('favorite.add')}
                </Typography>
            </span>
        </button>
    );
};
