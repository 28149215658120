import { InlineVideo } from '@components/features/inline-video/inline-video';
import {
    Modal,
    ModalContent,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/modal/modal';
import type { FC } from 'react';
import css from './inline-video-modal.module.scss';

type InlineVideoModalProps = {
    id: number;
    title: string;
    isOpened: boolean;
    onClose: () => void;
};

export const InlineVideoModal: FC<InlineVideoModalProps> = ({
    id,
    title,
    isOpened,
    onClose,
}) => {
    return (
        <Modal
            className={css.root}
            title={title}
            isOpen={isOpened}
            onClose={onClose}
        >
            <ModalContent>
                {isOpened && <InlineVideo id={id} title={title} autoPlay />}
            </ModalContent>
        </Modal>
    );
};
