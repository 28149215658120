'use client';

import type { FC, PropsWithChildren } from 'react';
import type { ConsentData } from '../types';
import { ConsentContext } from './context';

interface ConsentProviderProps extends PropsWithChildren {
    data: ConsentData;
}

export const ConsentProvider: FC<ConsentProviderProps> = ({
    data,
    children,
}) => {
    return (
        <ConsentContext.Provider value={data}>
            {children}
        </ConsentContext.Provider>
    );
};
