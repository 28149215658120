'use client';

import type { AdheseAd, AdheseSlotHooks } from '@adhese/sdk';
import { AdheseSlot } from '@adhese/sdk-react';
import {
    type PromoMetaProps,
    emitClickPromo,
    promoBatchService,
} from '@royalaholddelhaize/ah-analytics';
import { useInView } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-in-view/use-in-view';
import {
    type FC,
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { getAnalyticsProps } from './helpers/get-analytics-props';
import { validateRequest } from './helpers/validate-request';
import type {
    AdheseContract,
    SpotlightAHRMSSlotProps,
} from './spotlight-ahrms.interface';
import { SpotlightPlaceholder } from './spotlight-placeholder';
import { SpotlightTemplate } from './spotlight-template';

export const SpotlightSlotAHRMS: FC<SpotlightAHRMSSlotProps> = ({
    format,
    slot,
    ...restProps
}) => {
    const [isRendered, setIsRendered] = useState(false);
    const [cid, setCidObject] = useState<PromoMetaProps | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const { isInView } = useInView({
        ref,
        threshold: 0.5,
        once: true,
    });

    const handleClick = () => {
        if (cid) {
            emitClickPromo(cid);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isInView && isRendered && cid) {
            promoBatchService.load(cid);
            ref?.current?.addEventListener('click', handleClick);
        }

        return () => {
            ref?.current?.removeEventListener('click', handleClick);
        };
    }, [isInView, isRendered, cid]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const setup = useCallback(
        (
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            _: any,
            { onRender, onRequest }: AdheseSlotHooks,
        ) => {
            onRender(() => {
                if (!isRendered) {
                    setIsRendered(true);
                }
            });
            onRequest((data: AdheseAd) => {
                const ad = data?.tag as AdheseContract;
                validateRequest(ad);

                if (!cid) {
                    const cidObject = getAnalyticsProps(
                        data as AdheseAd<AdheseContract>,
                        slot,
                    );
                    setCidObject(cidObject);
                }
            });
        },
        [],
    );

    return (
        <MemoizedAdheseSlot
            ref={ref}
            format={format}
            slot={slot}
            setup={setup}
            {...restProps}
        />
    );
};

type MemoizedAdheseSlotProps = SpotlightAHRMSSlotProps & {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    setup: (_: any, { onRender, onRequest }: AdheseSlotHooks) => void;
};

const MemoizedAdheseSlot = memo(
    forwardRef<HTMLDivElement, MemoizedAdheseSlotProps>(
        ({ format, slot, setup, ...restProps }, ref) => (
            <div ref={ref} data-testhook="spotlight-ahrms-slot" {...restProps}>
                <AdheseSlot
                    height="100%"
                    width="100%"
                    format={format}
                    slot={slot}
                    setup={setup}
                    render={slot => <SpotlightTemplate {...slot} />}
                    renderMode="inline"
                    placeholder={<SpotlightPlaceholder />}
                />
            </div>
        ),
    ),
);
