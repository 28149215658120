/**
 * Normalize tag
 *
 * Should only include:
 * - Lowercase plain letters (so only the 26 letters available in the alphabet)
 * - Numbers
 * - Underscores `_`
 * - `&` should be replace by `en`
 * - All other signs should be replaced by one of these options..
 */
export const normalizeTag = (tag: string) =>
    tag
        .normalize('NFD')
        .replace(/&/g, 'en')
        .replace(/[- /\\]/g, '_')
        .replace(/[^a-z0-9_]/g, '')
        .split('_')
        .filter(e => e.length > 0)
        .join('_');
